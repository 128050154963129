<template>
    <div class="w-full h-full fixed pt-[10px] bottom-0 z-[999] bg-[#FFF]">
        <div @click="$emit('close')" class="flex text-[#4A4E69] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#22223B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-[#4A4E69]">
                Назад
            </div>
        </div>

        <div class="border-b-[1px] border-[#4A4E69] pb-2 text-gray-emb text-[20px] font-bold text-center w-[90%] mx-auto">
            {{ trainings[0].date.split('T')[0].split('-').reverse().join('.') }}
        </div>

        <div class="mt-[20px] h-[83%] overflow-y-auto">
            <SquircleComponent 
                v-for="training in allTrainings" 
                :smooth="70" 
                :radius="44" 
                :width="354" 
                :height="78" 
                :outlineWidth="0"
                class="flex items-center justify-center text-[#4A4E69] px-[10px] mx-auto mb-[20px]"
                :class="{
                    'bg-[#E7F1F1] h-[76px] pt-[10px] rounded-[20px] ' : training?.isAttended,
                    'border-green-emb border-[4px] h-[76px] pt-[10px] rounded-[20px] ' : training?.isBooked,
                    'border-red-atten border-[4px] h-[76px] pt-[10px] rounded-[20px] ' : training?.isMissed,
                    'bg-transparent h-[76px] pt-[10px] rounded-[20px] border-transparent border-[2px]' : !training?.isBooked && !training?.isAttended,
                    'hidden' : training?.status === 'completed' && !training?.isAttended && !training?.isMissed
                }"    
                @click="emitTraining(training) "
            >
                <div class="w-[52.1px] h-[44px] mr-[10px] relative bottom-[5px] rounded-full overflow-hidden border-[2px] border-[#525A60] ">
                    <img v-if="training?.trainer_photo" class="w-full h-full " :src="apiURL + 'static/' + training?.trainer_photo">
                    <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ training.trainer_label.split(' ')[0][0].toUpperCase() + training.trainer_label.split(' ')[1][0].toUpperCase() }}</div>
                </div>
                <div class="w-full mt-[px]">
                    <div class="w-full border-b-[0.2px] border-[#fff] border-opacity-20 pb-[10px]">
                        <div class="flex w-full">
                            <div class="font-bold relative text-[16px] text-gray-emb bottom-[5px] w-[37%]">{{ training.trainer_label.split(' ')[0] + ' ' + training.trainer_label.split(' ')[1][0] + '.'  }}</div>
                            <div class="font-bold text-[13px] relative text-gray-emb bottom-[1px]">{{ training.tariff_group_name }}</div>
                        </div>
                        <div class="flex justify-start w-full">
                            <div class="flex w-[37%] relative top-[6px]">
                                <div class="text-[11px] text-gray-emb">{{ training.start_time }}</div>
                                <div class="mx-[5px] text-[11px] text-gray-emb">
                                    <svg class="scale-50 origin-center relative top-[1px]" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 11L5.29289 6.70711C5.62623 6.37377 5.79289 6.20711 5.79289 6C5.79289 5.79289 5.62623 5.62623 5.29289 5.29289L1 1" stroke="#22223B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div class="text-[11px] text-gray-emb">{{ training.end_time }}</div>
                            </div>
                            <div class="text-start text-[11px] relative top-[6px]">{{ parseType(training.type) }}</div>

                            <div class="flex ml-auto mt-[-13px]">
                                <div class="text-[11px] w-[55px] mt-[4px] text-gray-emb">
                                    {{training.location_label}}
                                </div>
                                <div 
                                    class="truncate text-[#6BA292] font-bold w-[30px] text-start"
                                    :class="{
                                        'text-[#6BA292]' : training.clientsCount < training.max_clients,
                                        'text-[#BF637D]' : training.clientsCount >= training.max_clients
                                    }"    
                                >{{ training.clientsCount }}/{{ training.max_clients }}</div>
                                <div 
                                    class="h-[22px] w-[22px] border-[2px] ml-[5px] rounded-full"
                                    :class="{
                                        'bg-[#6BA292] border-[#6BA292]' : training.clientsCount < training.max_clients,
                                        'bg-[#BF637D] border-[#BF637D]' : training.clientsCount >= training.max_clients
                                    }"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </SquircleComponent>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, computed } from 'vue'
    import SquircleComponent from '@/mobileApp/components/SquircleComponent.vue'

    export default {
        name: 'multi-training-viewer',

        props: {
            trainings: {
                type: Array,
                default: () => []
            },
            clientTarriffName: {
                type: String,
                default: ''
            }
        },

        setup(props, { emit }) {
            const apiURL = ref('')

            const emitTraining = (training) => {
                if (training.isBooked && !training.isAttended && !training.isMissed) {
                    emit('bookedTrainingShow', training)
                } 
                else if (training.clientsCount >= training.max_clients && !training.isBooked && !training.isAttended && !training.isMissed) {
                    emit('fullTrainingAlert', training)
                }
                else if (training.isAttended) {
                    emit('completedTrainingShow', [training])
                } else if (training.isMissed) {
                    emit('missedTrainingShow', [training])
                }
                else {
                    emit('planedTrainingShow', training)
                }
                // else {
                //     if (training.clientsCount >= training.max_clients) {
                //         emit('fullTrainingAlert', training)
                //     } else {
                //         emit('planedTrainingShow', training)
                //     }

                // }
            }

            onMounted(() => {
                console.log('11231')
                console.log(props.trainings)
                apiURL.value = process.env.VUE_APP_API_URL
            })

            const allTrainings = computed(() => {
                let trainings = props.trainings.sort((a, b) => {
                    return a.start_time.localeCompare(b.start_time)
                })
                if (props.clientTarriffName.toUpperCase() === 'TEAM') {
                    trainings = trainings.filter((training) => {
                        return training.superposition === false
                    })
                }
                return trainings
            })

            const parseType = (type) => {
                switch (type) {
                    case 'yoga':
                        return 'йога'
                    case 'barre':
                        return 'барре'
                    case 'pilates_ref':
                        return 'пілатес(реф)'
                    case 'pilates_mat':
                        return 'пілатес(мат)'
                    case 'straching':
                        return 'стретчінг'
                }
            }

            return {
                apiURL,
                emitTraining,
                parseType,
                allTrainings
            }
        },

        components: {
            SquircleComponent
        }
    }
</script>

<style lang="scss" scoped>

</style>